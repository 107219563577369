<template>
   <section>
      <div class="">
         <!-- form -->

         <!-- //form -->

         <!-- Tableau pour afficher les utilisateurs-->
         <div class="tableau">
            <b-card no-body class="">
               <b-row class="px-2 py-2">
                  <!-- Per Page -->
                  <b-col
                     cols="12"
                     md="6"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
                  >
                     <label>Entrées</label>
                     <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                     />
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="3" class="mt-1">
                     <b-form-datepicker
                        id="example-datepicker"
                        v-model="value"
                        class="mb-1"
                     />
                  </b-col>

                  <b-col cols="12" md="3" class="mt-1">
                     <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="input-group-merge">
                           <b-form-input
                              v-model="filtreTicket"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : Nom, role, contact"
                           />
                        </b-input-group>
                     </div>
                  </b-col>
               </b-row>
               <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="tickets"
                  :fields="tableColumns"
                  :filter="filtreTicket"
                  :sort-by.sync="currentSort"
                  :sort-desc.sync="currentSortDir"
                  show-empty
                  empty-text=""
                  class="bg-white"
               >
                  <!-- new butt -->

                  <template #cell(entreprise)="data">
                     <span v-if="data.item.entreprise">
                        {{ data.item.entreprise.libelle }}
                     </span>
                  </template>

                  <template #cell(created_at)="data">
                     <span> {{ format_date(data.item.created_at) }} </span>
                  </template>

                  <template #cell(status)="data">
                     <b-badge
                        pill
                        variant="warning"
                        v-if="data.item.status === 0"
                     >
                        En attente
                     </b-badge>

                     <b-badge
                        pill
                        variant="primary"
                        v-else-if="data.item.status === 1"
                     >
                        Repondu</b-badge
                     >

                     <b-badge
                        pill
                        variant="success"
                        v-else-if="data.item.status === 2"
                     >
                        Resolu</b-badge
                     >
                  </template>

                  <template #cell(actions)="data">
                     <b-button
                        :disabled="data.item.status === 2 ? true : false"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon rounded-circle"
                        @click="reply(data.item)"
                     >
                        <feather-icon icon="MessageSquareIcon" />
                     </b-button>

                     <b-button
                        :disabled="data.item.status === 2 ? true : false"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="btn-icon rounded-circle my-1"
                        @click="updateStatus(data.item.id)"
                     >
                        <feather-icon icon="CheckIcon" />
                     </b-button>

                     <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon rounded-circle"
                        @click="confirmDestroy(data.item.id)"
                     >
                        <feather-icon icon="Trash2Icon" />
                     </b-button>
                  </template>
               </b-table>

               <!-- Les boutons de pagination -->
               <div class="mx-2 mb-2 pb-1">
                  <b-row>
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                     >
                        <span class="text-muted"></span>
                     </b-col>
                     <!-- Pagination -->
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                     >
                        <b-pagination
                           v-model="currentPage"
                           :total-rows="ticketTotal"
                           :per-page="perPage"
                           first-number
                           last-number
                           class="mb-0 mt-1 mt-sm-0"
                           prev-class="prev-item"
                           next-class="next-item"
                        >
                           <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                           </template>
                           <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                           </template>
                        </b-pagination>
                     </b-col>
                  </b-row>
               </div>
            </b-card>
         </div>
      </div>
   </section>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BTable,
   BPagination,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
   BFormTextarea,
   BBadge,
   VBToggle,
   BFormDatepicker,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/request';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import { computed } from '@vue/composition-api';
import VueCountryCode from 'vue-country-code-select';
import 'moment/locale/fr';
import moment from 'moment';


export default {
   components: {
      moment,
      BFormDatepicker,
      ToastificationContent,
      VueCountryCode,
      flatPickr,
      vSelect,
      VBToggle,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BTable,
      BPagination,

      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BFormTextarea,
      BInputGroupAppend,
      BBadge,

      // validations
      ValidationProvider,
      ValidationObserver,
   },
   directives: {
      Ripple,
      'b-toggle': VBToggle,
   },
   mixins: [heightTransition],
   data() {
      return {
         tickets: '',
         ticketTotal: 0,
         filtreTicket: '',
         perPage: 30,
         currentPage: 1,
         currentSort: 'created_at',
         currentSortDir: 'asc',
         tableColumns: [
            { key: 'code', label: 'Code' },
            { key: 'entreprise', sortable: true },
            { key: 'libelle', label: 'Libelle' },
            { key: 'service', label: 'service', sortable: true },
            { key: 'priorite', label: 'priorite', sortable: true },
            { key: 'created_at', label: 'date', sortable: true },
            { key: 'status', label: 'status', sortable: true },

            { key: 'actions' },
         ],
         perPageOptions: [30, 50, 100, 150],
      };
   },
   mixins: [heightTransition],

   async mounted() {
 
      document.title = 'Ticket - Ediqia';

     

      axios.get(URL.LIST_TICKET).then((response) => {
         console.log(response.data);

         this.tickets = response.data;
         this.ticketTotal = response.data.length;
      });
   },

   methods: {
      //fonction repondre au ticket

      reply(data) {
         const id = data.id;
         this.$router.push({ name: 'message', params: { id: id } });

         // const group = this.tickets.filter((item)=>{
         //   return item.user_id === data.user_id
         // })
         // console.log('group',group);
         // localStorage.setItem('ticketEnCour',JSON.stringify(data))
         //       localStorage.setItem('ticketRecent',JSON.stringify(group))
      },

      //formatage date(il y'a xxxx)
      format_date(value) {
         if (value) {
            moment.locale('fr');
            return moment(String(value)).fromNow();
         }
      },

      //toast
      success(variant) {
         this.$toast({
            component: ToastificationContent,
            props: {
               title: 'Notification',
               icon: 'BellIcon',
               text: '👋 Operation reussi avec success.',
               variant: 'success',
            },
         });
      },

      //fonction destroy

      confirmDestroy(id) {
         this.$swal({
            title: 'Êtes vous sûr?',
            text: 'Ce ticket sera supprimé définitivement !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
         }).then((result) => {
            if (result.value) {
               this.delete(id);
               this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                     confirmButton: 'btn btn-success',
                  },
               });
            }
         });
      },

      delete(identifiant, index) {
         try {
            const data = {
               id: identifiant,
            };

            axios.post(URL.TICKET_DESTROY, data).then((response) => {
               if (response.data) {
                  axios.get(URL.LIST_TICKET).then((response) => {
                     console.log(response.data);
                     this.tickets = response.data;
                     this.ticketTotal = response.data.length;
                  });
               }
            });
            this.tickets.splice(index, 1);
         } catch (error) {
            console.log(error);
         }
      },

      //fonction statusUpdate

      updateStatus(id) {
         this.$swal({
            title: 'Êtes vous sûr?',
            text: 'Ce ticket est réglé ? ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
         }).then((result) => {
            if (result.value) {
               this.status(id);
               this.$swal({
                  icon: 'success',
                  title: 'Updated!',
                  text: 'Status has been changed.',
                  customClass: {
                     confirmButton: 'btn btn-success',
                  },
               });
            }
         });
      },

      status(identifiant) {
         try {
            const data = { id: identifiant };
            axios.post(URL.TICKET_STATUS, data).then((response) => {
               if (response.data) {
                  axios.get(URL.LIST_TICKET).then((response) => {
                     console.log(response.data);
                     this.tickets = response.data;
                     this.ticketTotal = response.data.length;
                  });
               }
            });
         } catch (error) {
            console.log(error);
         }
      },

      //fonctionn add

      //fonction update

      //fonction destroy
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-client-header {
   padding: $options-padding-y $options-padding-x;
   color: $success;
   &:hover {
      background-color: rgba($success, 0.12);
   }
}

.tableau {
   box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
   width: 100%;
   margin: auto;
   border-radius: 13px;
}

.table-card thead tr {
   border-radius: 13px;
   background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
   background-color: rgb(68, 68, 68) !important;
   color: white;
}

.add-btn {
   position: absolute;
   right: 0;
   top: -50px;
   background-color: #450077;
}

.none {
   display: none;
}
.block {
   display: inline-block;
}
</style>
